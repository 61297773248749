<template>
  <div class="bg">
    <van-nav-bar
      :fixed="true"
      z-index="10"
      placeholder
      title="vip"
      left-arrow
      @click-left="onClickLeft"
    />
    <ul class="levels_wrap">
      <li v-for="(item, index) in levelData" :key="index">
        <div
          class="content_wrap"
          :style="{
            backgroundImage:
              'url(' +
              require(`../../../assets/icon/vipLevel_icon/bg-vip${index}.png`) +
              ')',
            backgroundColor: backgroundArr[index],
          }"
        >
          <div class="flex_wrap">
            <div class="content_left">
              <div class="title">vip{{ index + 1 }}</div>
              <div class="content">
                <!-- <p>1.Tasks are randomly matched by the system {{ orderNumObj[index] }} single</p> -->
                <p>
                  1.Tasks are randomly matched by the system
                  {{ item.order_num == 1 ? "" : "1~"
                  }}{{ item.order_num }} single
                </p>
                <p>
                  2.Order placement commission is uniformly based on the order
                  amount {{ item.rate }}%
                </p>
                <p>
                  3.The system is based on LSB technology and automatically
                  matches merchandise orders through the cloud
                </p>
              </div>
            </div>
            <div class="content_right">
              <img
                :src="
                  require(`../../../assets/icon/vipLevel_icon/vip${index}.png`)
                "
                alt=""
              />
            </div>
          </div>
          <div class="content_bottom">
            <p>
              Commissions available at this level {{ item.rate }}%<br />
              <!-- Odds that can be performed at this leve {{item.order_num}}single -->
            </p>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Tab,
  Tabs,
  Image,
  NoticeBar,
  Field,
  DropdownMenu,
  DropdownItem,
  Popup,
  DatetimePicker,
  Toast,
} from "vant";
export default {
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
    [Image.name]: Image,
    [NoticeBar.name]: NoticeBar,
    [Field.name]: Field,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Popup.name]: Popup,
    [DatetimePicker.name]: DatetimePicker,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    Toast,
  },
  data() {
    return {
      levelData: [],
      backgroundArr: [
        "#bcbdcd",
        "#edccbb",
        "#d2d0e8",
        "#83d4f7",
        "#f1d178",
        "#a794f8",
      ],
      orderNumObj: {
        0: 1,
        1: "1~3",
        2: "1~6",
        3: "1~7",
        4: "1~8",
        5: "1~9",
      },
    };
  },
  methods: {
    // 返回上一个路由
    onClickLeft() {
      this.$router.go(-1);
    },
    getLevels() {
      Toast.loading({
        forbidClick: true,
        duration: 0,
      });
      // if(this.$cookie.get('levels')){
      //   this.levelData = JSON.parse(this.$cookie.get('levels'));
      // }
      this.$api.getLevel(
        (res) => {
          Toast.clear();
          this.levelData = res;
        },
        (err) => {
          Toast.clear();
        }
      );
    },
  },
  created() {
    this.getLevels();
  },
  mounted() {},
  destroyed() {},
};
</script>

<style lang="less" scoped>
.bg {
  background-color: #f7f8f9;
  > .levels_wrap {
    width: 94%;
    margin: 0 auto;
    > li {
      width: 100%;
      min-height: calc(196rem / 16);
      border-radius: 7px;
      margin: 11px 0;
      > .content_wrap {
        min-height: calc(196rem / 16);
        border-radius: 7px;
        padding: calc(12rem / 16) calc(11rem / 16) calc(9rem / 16)
          calc(13rem / 16);
        background: url("../../../assets/icon/vipLevel_icon/bg-vip0.png")
          top/100% no-repeat;
        background-color: #bcbdcd;
        > .flex_wrap {
          display: flex;
          justify-content: space-between;
          > .content_left {
            > .title {
              font-size: 27px;
              color: #fff;
              font-style: italic;
              font-weight: 700;
            }
            > .content {
              color: rgb(87, 90, 107);
              word-break: break-all;
              font-size: calc(13rem / 16);
              line-height: calc(20rem / 16);
              margin-top: calc(5rem / 16);
            }
          }
          > .content_right {
            > img {
              width: calc(104rem / 16);
              height: calc(122rem / 16);
            }
          }
        }
        > .content_bottom {
          word-break: break-all;
          background-color: rgb(87, 90, 107);
          border-radius: 4px;
          opacity: 0.69;
          font-size: calc(13rem / 16);
          color: #fff;
          margin-top: calc(18rem / 16);
          padding: calc(5rem / 16) calc(7rem / 16);
          line-height: 1.3;
        }
      }
    }
  }
}
</style>